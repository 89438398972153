@import url(https://maxcdn.bootstrapcdn.com/bootstrap/3.3.6/css/bootstrap.min.css);
body {
  margin: 0;
  font-family: LuloCleanW01-One, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: 'LuloCleanW01-One';
  src: local('LuloCleanW01-One'), url(/static/media/FontsFree-Net-Lulo-Clean-W01-One.2a49f464.ttf) format('truetype');
  /* other formats include: 'woff2', 'truetype, 'opentype',
                            'embedded-opentype', and 'svg' */
}
.App {
  text-align: center;
  font-family: 'MyWebFont';
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

@font-face {
  font-family: 'MyWebFont';
  src: url(/static/media/FontsFree-Net-Lulo-Clean-W01-One.2a49f464.ttf) format('truetype')
}

@font-face {
  font-family: 'TestFont';
}

.wallet-status {
  position: absolute;
  top: 55px;
  display: -webkit-inline-flex;
  display: inline-flex;
  right: 400px;
}

.deactiveshow {
  color: gray;
}

.event-input {
  width: 294px !important;
  font-size: 12px !important;
  line-height: 17px !important;
  border-radius: 0 !important;
  border: none !important;
  border-bottom: 1px solid #000000 !important;
  box-shadow: none !important;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  font-family: 'MyWebFont';
}

.event-input:focus {
  border:  none;
}

.event-input > input {
  border: none;
  box-shadow: none;
  text-align: center;
}

.event-input > input:focus {
  border: none;
  box-shadow: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}


/*.MyContainer div {
  margin: 10px auto;
  width: 80%;
}*/

.centered {
text-align: center;
color: white;
border: 1px solid;
/* width: 160px; */
background-color: black;
border-radius: 20px;
padding: 16px;
margin-left: 400px;
margin-right: 400px;
margin-top: 100px;
}

.wallet-button {
  width: 190px;
  height: 45px;
  line-height: 45px;
  display: inline-block;
  font-size: 10px;
  background: #242429;
  text-align: center;
  border-radius: 25px;
  color: white;
  text-decoration: none;
  cursor: pointer;
  font-family: "LuloCleanW01-One";
}

.wallet-button:hover {
  text-decoration: none;
  color: white;
}

.connect-button {
  cursor: pointer;
  width: 280px;
  height: 40px;
  line-height: 40px;
  font-size: 14px;
  padding: 0px 15px;
  text-decoration: none;
  color: #000000;
  border: 1px solid #d5d5d5;
  background: #e5e5e5;
  display: inline-block;
  border-radius: 5px;
  position: relative;
}


a:hover {
  text-decoration: none;
}
.createWalletcentered {
  text-align: center;
  color: white;
  border: 1px solid;
  /* width: 160px; */
  background-color: black;
  border-radius: 20px;
  padding: 16px;
  margin-left: 350px;
  margin-right: 50px;
  margin-top: 10px;
  }
.divstyle {
  margin-top: 10px;
  padding-top: 1px;
  padding-bottom: 10px;
}
.divnextBoxstyle {
  margin-top: 10px;
  padding-top: 1px;
  padding-bottom: 10px;

}
.metamaskcontainer {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
}
.metamaskcontainer .product {
  /* flex: 1 0 33%; */
  /* height: 50px; */
  /* border: 1px solid black; */
}
.p {
  word-spacing: 30px;
}

.row-bordered:after {
  border-bottom: 5px solid red;
}
.Row {
  display: table;
  width: 100%; /*Optional*/
  table-layout: fixed; /*Optional*/
}
.Column {
  display: table-cell;
}
.dot {
  height: 20px;
  width: 20px;
  background-color: #bbb;
  border-radius: 50%;
  display: inline-block;
  color: green;
}
